import React, { useContext } from 'react'
import { CurrencyType } from '../globals'

// Material Ui
import Typography from '@material-ui/core/Typography';

// Contexts
import { DataContext } from '../_contexts/data.context';

const Price = (props) => {
    const { Item, large, calculateTotal } = props;
    const { Credentials } = useContext(DataContext); // Get Config of this menu from the Data Context
    const variant = large ? 'h4' : 'body2';

    let price;

    if (calculateTotal)
        price = Item.Price * Item.Quantity;
    else
        if (Item.Price && Item.Price > 0)
            price = Item.Price;
        else
            if (Item.Options && Item.Options.length > 0) {
                const pricelist = [...new Set(Item.Options.filter(option => option.Type === 2)[0].Items.map(item => item.Price))].sort((a, b) => a - b);
                price = pricelist.join(' - ')
            }

    if (price) {
        switch (Credentials.SelectedCurrencyId) {
            case CurrencyType.TRY:
                price = `${price} ₺`;
                break;
            case CurrencyType.USD:
                price = `$ ${price}`;
                break;
            case CurrencyType.EURO:
                price = `€ ${price}`;
                break;
            case CurrencyType.POUND:
                price = `£ ${price}`;
                break;
            case CurrencyType.AED:
                price = `AED ${price}`;
                break;
            case CurrencyType.RUB:
                price = `Rub ${price}`;
                break;
            case CurrencyType.QAR:
                price = `QAR ${price}`;
                break;
            case CurrencyType.SR:
                price = `SR ${price}`;
                break;
            case CurrencyType.OMR:
                price = `OMR ${price}`;
                break;
            case CurrencyType.KWD:
                price = `KWD ${price}`;
                break;
            case CurrencyType.EGP:
                price = `EGP ${price}`;
                break;
            case CurrencyType.BHD:
                price = `BHD ${price}`;
                break;
            case CurrencyType.JD:
                price = `JD ${price}`;
                break;
            case CurrencyType.DKK:
                price = `DKK ${price}`;
                break;
            case CurrencyType.CAD:
                price = `CAD ${price}`;
                break;
            case CurrencyType.AZN:
                price = `AZN ${price}`;
                break;
            default:
                price = `${price} ₺`;
        }
    }
    else
        price = '';


    return <Typography variant={variant} style={{ paddingRight: '4px', whiteSpace: 'nowrap' }}>{price}</Typography>
}

export default Price;